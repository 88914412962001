<template lang='pug'>
v-card(v-if="Object.keys(dealingById).length").pa-3
  v-card-title.d-flex.justify-space-between.w-100
    div {{ ($t(`${Object.keys(dealingById?.behavior)[0]}-backOfficeDealing`, { course: dealingById[nameLang] })).toUpperCase() }}
    ControlButtons(:controlButtons="controlButtons").justify-end
  v-progress-linear(v-if="isLoading" indeterminate color="cyan")
  v-card-text
    v-divider
    Info(v-if="dealingById?.behavior?.viewInfoBlock" :dealingById="dealingById")
    Edit(v-if="dealingById?.behavior?.viewEditBlock" :dealingById="dealingById")
</template>
<script>
import { mapActions, mapState } from 'vuex'
import { viewDetailedComponent } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
// import { STATUSES_STATEMENT_CONSTANTS } from '@/configs/constants'
export default {
  name: 'DocumentDealing',
  components: {
    ControlButtons: () => import('@/components/atoms/ControlButtons'),
    Info: () => import('./Info.vue'),
    Edit: () => import('./Edit.vue')
  },
  data () {
    return {
      controlButtons: [
        {
          id: 1,
          name: 'mdi-information-outline',
          action: () => viewDetailedComponent(this.dealingById, 'viewInfoBlock'),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.info',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 2,
          name: 'mdi-pen',
          action: () => viewDetailedComponent(this.dealingById, 'viewEditBlock'),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.edit',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        },
        {
          id: 3,
          name: 'mdi-close',
          action: () => this.$router.push({ name: 'dealing-backoffice', query: { ...this.$route.query } }),
          checkAccess: () => checkAccess('backoffice'),
          color: '#42627e',
          tooltip: 'documentIconControl.close',
          buttonOptions: { xSmall: false, fab: true, icon: true, color: '#42627e', outlined: false }
        }
      ]
    }
  },
  computed: {
    ...mapState({
      dealingById: state => state.backoffice.dealingById,
      isLoading: state => state.backoffice.isLoading,
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  },
  mounted () {
    this.getData()
  },
  methods: {
    ...mapActions(['getDealingById']),
    async getData () {
      await this.getDealingById({ ...this.$route.params })
    }
  }
}
</script>
